import './home.scss';

import { swiper_mobile_init } from 'js/functions';

swiper_mobile_init();

document.body.addEventListener('swiper_mobile_init', (e)=>{
    new e.Swiper_Mobile({
        container: '.section-box', // parent element
        items: '.box', // (.sample_1 .item) wrap with swiper-wrapper > swiper-slide
        bullets: true, // swiper-pagination
        extend_swiper_settings: {
            spaceBetween: 40,
        }
    })
})